<template>
	<div class="list1">
		<div v-if="listData && listData.length>0" class="list">
			<div class="list_list">
				<div class="left" :style="'background-image: url('+listData[current].listImg+');'" @click="goItem(listData[current])"></div>
				<div class="right">
					<div class="right_div">
						<div v-for="(item, index) in listData" :key="index" :class="'list_item'+(index==current?' current':'')" @mouseover="current=index" @click="goItem(item)">
							<div class="list_title">{{item.listTitle}}</div>
							<div class="list_desc">{{item.listDesc}}</div>
						</div>
					</div>
				</div>
			</div>
			<template v-if="from && from=='list'">
				<div class="pagination only_pc">
					<el-pagination @current-change="changePage" background layout="prev, pager, next" :page-size.sync="pageSize" :current-page.sync="page" :total="total"></el-pagination>
				</div>
				<div class="pagination only_mob">
					<el-pagination @current-change="changePage" small background layout="prev, pager, next" :page-size.sync="pageSize" :current-page.sync="page" :total="total"></el-pagination>
				</div>
			</template>
		</div>
		<div v-else-if="!loading" class="list_empty">
			<span>暂无数据</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'List',
		props: ['data', 'pageNum', 'pageSize', 'total', 'loading', 'from'],
		data() {
			var listData=this.data || []
			listData.forEach(item=>{
				if(!item.listImg || item.listImg.length<=0)item.listImg='/images/empty_image.png'
			})
			return {
				page: this.pageNum || 1,
				listData: listData,
				current: 0
			}
		},
		methods: {
			goItem(item) {
				if(this.from && this.from == 'list') {
					item.listLink = '/page/' + item.id
				}
				if(item.listLink && item.listLink.length > 0) {
					this.$router.push({
						path: item.listLink
					})
				}
			},
			changePage(num) {
				this.$parent.changePage(num)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.list1 {
		@include body_block;
		min-height: 100px;
		.list {
			.list_list {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				background: #f4f4f4;
				border-radius: 6px 0 0 6px;
				height: 520px;
				@media (max-width: $mob-screen) {
					height: 200px;
				}
				.left {
					@include pic_bg_cover;
					width: 73%;
					cursor: pointer;
					height: 100%;
					border-radius: 6px;
					@media (max-width: 800px) {
						width: 60%;
					}
				}
				.right {
					width: 27%;
					height: 100%;
					padding: 26px 0;
					@media (max-width: 800px) {
						width: 40%;
					}
					@media (max-width: $mob-screen) {
						padding: 8px 0;
					}
					.right_div {
						overflow: auto;
						height: 100%;
						.list_item {
							padding: 23px 34px;
							cursor: pointer;
							@media (max-width: $mob-screen) {
								padding: 8px;
							}
							.list_title {
								line-height: 33px;
								font-size: 24px;
								@media (max-width: $mob-screen) {
									@include single_line_ellipsis;
									line-height: 24px;
									font-size: 16px;
								}
							}
							.list_desc {
								line-height: 25px;
								font-size: 18px;
								color: #7a7a7a;
								@media (max-width: $mob-screen) {
									@include single_line_ellipsis;
									line-height: 18px;
									font-size: 14px;
								}
							}
						}
						.current {
							background: #fff;
							.list_title {
								color: $--color-primary;
							}
						}
					}
				}
			}
			.pagination {
				margin-top: 16px;
				text-align: right;
				@media (max-width: $mob-screen) {
					text-align: center;
				}
			}
			.only_pc {
				display: block;
				@media (max-width: $mob-screen) {
					display: none;
				}
			}
			.only_mob {
				display: none;
				@media (max-width: $mob-screen) {
					display: block;
				}
			}
		}
		.list_empty {
			color: #ccc;
			font-size: 18px;
			@media (max-width: $mob-screen) {
				font-size: 14px;
			}
		}
	}
</style>